<template>
  <div v-if="isVisible" class="document-list-item texture">
    <span v-if="isLogo">Logo:</span>
    <b-button
      variant="danger"
      v-b-popover.hover.topright="`Remove ${filename} from ${projectName}`"
      size="sm"
      v-if="isAdmin && !isLogo"
      id="remove-document-btn"
      @click="removeDocument"
    >X</b-button>
    <a :href="downloadLink">
      <i class="ml-2 mr-2 fa fa-cloud-download"/>
      {{filename}}
    </a>
  </div>
</template>

<script>
import { del } from "@/utils/api";
export default {
  props: [
    "id",
    "filename",
    "projectName",
    "isAdmin",
    "isLogo",
    "isBucket",
    "projectId"
  ],
  data() {
    return {
      isVisible: true
    };
  },
  computed: {
    downloadLink() {
      return (
        process.env.VUE_APP_ROOT_API +
        "barrier-management/project/" +
        this.projectId +
        "/document/" +
        this.id
      );
    }
  },
  methods: {
    removeDocument() {
      this.isVisible = false;
      del(
        `barrier-management/document?project_id=${this.projectId}&document_id=${
          this.id
        }`
      ).then(() => {
        this.$emit("reloadData");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.document-list-item {
  padding: 5px;
  font-size: 18px;
  margin: 5px;
  align-items: center;
  display: flex;
}
#remove-document-btn {
  padding: 0px;
  width: 20px;
  font-size: 13px;
  margin-right: 23px;
}
</style>

