<template>
  <div :class="'documents ' + (isAdmin ? '' : 'mt-4')">
    <b-btn
      @click="addDocVisible = addDocVisible==='down'? 'up':'down'"
      class="mt-4 mb-3 add-doc-btn"
      v-b-toggle.collapse1
      variant="primary"
      v-if="isAdmin"
    >
      Add Document to {{projectName}}
      <i :class="'fa fa-caret-' + addDocVisible"/>
    </b-btn>
    <b-collapse v-if="isAdmin" id="collapse1" class="mt-2 mb-3">
      <b-card class="texture">
        <h5>Upload Project Documents</h5>
        <p>
          Max File Size:
          <strong>25 MB</strong>
        </p>
        <vue-dropzone
          ref="myVueDropzone"
          @vdropzone-success="dzSuccess"
          @vdropzone-error="dzError"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone>
      </b-card>
    </b-collapse>
    <div :class="'sections ' + (isAdmin ? 'slide-up' : '')">
      <div>
        <h3 class="mb-3">
          <u>Project Documents</u>
        </h3>
        <div class="mb-3 texture box">
          <a :href="bucketsLink">
            <i class="ml-2 mr-2 fa fa-cloud-download"></i> Firestop Systems
          </a>
          <br>
          <a
            v-for="doc in project.proj_docs"
            :key="doc"
            :href="getDocumentLink(doc)"
            class="capitalize"
          >
            <i class="ml-2 mr-2 fa fa-cloud-download"></i>
            {{ doc.toLowerCase().replace(/-/g,' ') }}
            <br>
          </a>
        </div>
        <h3 class="mb-3">
          <u>Submitted Documents</u>
        </h3>
        <DocumentListItem
          v-for="doc in visibleDocuments"
          :key="doc.doc_id"
          :id="doc.doc_id"
          :isLogo="doc.is_logo"
          :projectId="project.details.id"
          :filename="doc.name"
          :projectName="projectName"
          :isAdmin="isAdmin"
          @reloadData="$emit('reloadData')"
          class="box"
        />
      </div>
      <div v-if="false">
        <h3 class="mb-3">
          <u>BMP Report Sections</u>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
// import ReportSectionItem from "@/components/ReportSectionItem";
import DocumentListItem from "@/components/DocumentListItem";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: ["project", "isAdmin", "projectName"],
  components: {
    vueDropzone,
    DocumentListItem
    // ReportSectionItem
  },
  data() {
    return {
      file: null,
      addDocVisible: "down",
      bucketsLink:
        process.env.VUE_APP_ROOT_API +
        "barrier-management/report/" +
        this.project.details.id +
        "/buckets",
      dropzoneOptions: {
        url: process.env.VUE_APP_ROOT_API + "barrier-management/document",
        thumbnailWidth: 150,
        maxFilesize: 25,
        paramName: "document",
        params: {
          project_id: this.project.details.id
        },
        withCredentials: true,
        acceptedFiles:
          ".bmp,.pdf,.png,.jpg,.jpeg,.csv,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt,.rtf,.zip",
        dictDefaultMessage:
          "Drop project document files here or click to upload"
      }
    };
  },
  methods: {
    dzSuccess() {
      this.$emit("reloadData");
      // { doc_id: response, name: file.name }
    },
    dzError() {},
    getDocumentLink(docName) {
      return process.env.VUE_APP_ROOT_API + "storage/bmp/" + docName + ".pdf";
    }
  },
  computed: {
    visibleDocuments() {
      // Ignore default logo in submitted documents list
      return this.project.docs.filter(doc => doc.name !== "default_logo.jpg");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.documents {
  h3 {
    text-align: center;
  }
  .sections {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .slide-up {
    margin-top: -50px;
  }
  .box {
    border: 1px solid #ddd;
    box-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 0.4);
    padding: 10px;
  }
  .add-doc-btn {
    max-width: 200px;
  }
  .card {
    box-shadow: 4px 4px 2px -2px rgba(0, 0, 0, 0.4);
  }
  .capitalize {
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 825px) {
  .slide-up {
    margin-top: 0;
  }
}
</style>

